import React from 'react'
import '../styles/Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        Tomi Piisila © 2022 
        <br />
        All Rights Reserved
    </div>
  )
}

export default Footer